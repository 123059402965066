import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    startLoading: (state) => {
      return { ...state, loading: true };
    },
    stopLoading: (state) => {
      return { ...state, loading: false };
    },
  },
  extraReducers: (builder) => {},
});

export const { startLoading, stopLoading } = commonSlice.actions;
const { reducer } = commonSlice;
export default reducer;
