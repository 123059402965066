import RightArrowIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Container } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CButton from '../../components/CButton';
import CustomBreadcrumbs from '../../components/breadcrumbs/CustomBreadcrumbs';
import SettingSidebar from './SettingSidebar';

const Wallet = () => {
  const userDetails =
    JSON.parse(localStorage.getItem('user')) ||
    JSON.parse(sessionStorage.getItem('user'));
  const navigate = useNavigate();

  return (
    <>
      <div className="main-content">
        <Container fixed className="setting-container">
          <div className="breadcrumb-back">
            <CButton
              sx={{ border: ' 0 !important' }}
              onClick={() => navigate('/module')}
            >
              <RightArrowIcon />
            </CButton>
            <CustomBreadcrumbs
              links={[
                // {
                //   name: 'Modules',
                //   href: '/module',
                // },
                {
                  name: 'Wallet',
                  href: '',
                },
              ]}
            />
          </div>
          <div className="setting-content wallet-settings">
            <div className="card-box col-left">
              <SettingSidebar />
            </div>
            <div className="card-box col-right">
              <div className="wallet-content">
                <p>Your Balance</p>
                <span className="wallet-amt">
                  R 
                  {userDetails?.walletBalance === null
                    ? "0.00"
                    : userDetails?.walletBalance}
                </span>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Wallet;
