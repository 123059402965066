import { yupResolver } from "@hookform/resolvers/yup";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import RightArrowIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Container, Link, List, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ReactComponent as ProfileIcon } from "../../assets/images/profile.svg";
import { ReactComponent as WalletIcon } from "../../assets/images/wallet.svg";
import CButton from "../../components/CButton";
import CFormProvider from "../../components/CFormProvider";
import CFormTextField from "../../components/CFormTextField";
import CustomBreadcrumbs from "../../components/breadcrumbs/CustomBreadcrumbs";
import {
  fetchProfileDetails,
  removeProfileImage,
  uploadProfileImage,
} from "../../slices/profileSlice";
import CImageUpload from "../../components/CImageUpload";
import { toast } from "react-toastify";
import { getFormattedUrl } from "../../common/utils";
import SettingSidebar from "./SettingSidebar";

const Profile = ({ profileImgUrl, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profileDetails } = useSelector((state) => state.profile);
  const ProfileSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phone: Yup.string().required("Phone is required"),
    lmaContactNo: Yup.string().required("LMA Contact is required"),
    employerName: Yup.string().required("Employer Name is required"),
    employeeNumber: Yup.string().required("Employee Number is required"),
  });

  const [defaultValues, setDefaultValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    lmaContactNo: "",
    employerName: "",
    employeeNumber: "",
    email: "",
  });

  const methods = useForm({
    resolver: yupResolver(ProfileSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    profileDetails !== null && setDefaultValues(profileDetails);
  }, [profileDetails]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  const handleContractDownload = (event) => {
    event.stopPropagation();
    if (profileDetails?.contractDocument) {
      const url =
        profileDetails.contractDocument.startsWith("http") ||
        profileDetails.contractDocument.startsWith("https")
          ? profileDetails.contractDocument
          : `http://${profileDetails.contractDocument}`;

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "contract.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading contract:", error);
        });
    }
  };

  const handleProfileChange = (image) => {
    if (image.length) {
      const file = image[0].file;
      const fileType = file.type.split("/")[0];
      const fileSize = file.size / 1024 / 1024; // size in MB

      if (fileType !== "image") {
        toast.error("The uploaded file must be an image.");
        return;
      }

      if (fileSize > 3) {
        toast.error("The uploaded image must be less than 3 MB.");
        return;
      }

      let formDataPayload = new FormData();
      formDataPayload.append("image", file);
      dispatch(uploadProfileImage(formDataPayload)).then((res) => {
        if (!res.error) {
          localStorage.setItem(
            "profileImg",
            getFormattedUrl(res.payload.data.profile)
          );
          sessionStorage.setItem(
            "profileImg",
            getFormattedUrl(res.payload.data.profile)
          );
          dispatch(fetchProfileDetails());
        }
      });
    } else {
      dispatch(removeProfileImage()).then((res) => {
        if (!res.error) {
          localStorage.setItem("profileImg", null);
          sessionStorage.setItem("profileImg", null);
          dispatch(fetchProfileDetails());
        }
      });
    }
  };

  useEffect(() => {
    dispatch(fetchProfileDetails());
  }, [dispatch]);

  return (
    <>
      <div className="main-content">
        <Container fixed className="setting-container">
          <div className="breadcrumb-back">
            <CButton
              sx={{ border: " 0 !important" }}
              onClick={() => navigate("/module")}
            >
              <RightArrowIcon />
            </CButton>
            <CustomBreadcrumbs
              links={[
                {
                  name: "Dashboard",
                  href: "/module",
                },
                {
                  name: "My Profile",
                  href: "",
                },
              ]}
            />
          </div>
          <div className="setting-content">
            <div className="card-box col-left">
              <SettingSidebar />
            </div>
            <div className="card-box col-right">
              <div className="profile-content">
                <div className="profile-upload">
                  <CImageUpload
                    onChange={handleProfileChange}
                    defaultImage={profileDetails?.profile}
                    profileDetails={profileDetails}
                  />
                  <div className="profile-title-text">
                    {profileDetails && (
                      <h3>
                        {profileDetails?.firstName +
                          " " +
                          profileDetails?.lastName}
                      </h3>
                    )}
                    {/* <span className="profile-email-text">
                      johan.mark@testemail.com
                    </span> */}
                  </div>
                </div>
                {/* <CButton className="btn-primary edit-profile-btn">
                  Edit Profile
                </CButton> */}
              </div>
              <div className="edit-profile-form">
                <CFormProvider methods={methods} onSubmit={handleSubmit()}>
                  <CFormTextField
                    label="First Name"
                    name="firstName"
                    // placeholder="John"
                    // value="John"
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <CFormTextField
                    label="Last Name"
                    name="lastName"
                    // placeholder="Doe"
                    // value="Doe"
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <CFormTextField
                    label="Employer Name"
                    name="employerName"
                    // placeholder="1234567890"
                    // value="1234567890"
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <CFormTextField
                    label="Email"
                    name="email"
                    // placeholder="1234567890"
                    // value="1234567890"
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  {/* <CFormTextField
                    label="Phone Number"
                    name="phone"
                    // placeholder="1234567890"
                    // value="1234567890"
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  /> */}
                  <CFormTextField
                    label="Employee Number"
                    name="employeeNumber"
                    // placeholder="1234567890"
                    // value="1234567890"
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <CFormTextField
                    label="My Contact Number"
                    name="lmaContactNo"
                    // placeholder="1234567890"
                    // value="1234567890"
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  {/* <CFormTextField
                    label="Email ID"
                    name="emailID"
                    placeholder="johndoe@gmail.com"
                    value="johndoe@gmail.com"
                    InputLabelProps={{ shrink: true }}
                  /> */}
                </CFormProvider>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Profile;
