import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

const CLoader = () => {
  const { loading } = useSelector((state) => state.common);
  return (
    <>
      {loading && (
        <div className="CLoader">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default CLoader;
