// src/components/CommonButton.js
import React from "react";
import Button from "@mui/material/Button";

const CButton = ({
  children,
  startIcon,
  endIcon,
  className,
  type,
  ...props
}) => {
  return (
    <Button
      disableRipple
      disableFocusRipple
      type={type}
      startIcon={startIcon}
      endIcon={endIcon}
      className={`btn ${className}`}
      {...props}
    >
      {children}
    </Button>
  );
};
export default CButton;
