import { Container } from "@mui/material";
import React from "react";
import CButton from "../../components/CButton";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumbs from "../../components/breadcrumbs/CustomBreadcrumbs";
import RightArrowIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import employerLogo from "../../assets/images/lma-logo.png";
import { useSelector } from "react-redux";
import { getFormattedUrl } from "../../common/utils";
const AboutEmployer = () => {
  const navigate = useNavigate();
  const userDetails =
    JSON.parse(localStorage.getItem("user")) ||
    JSON.parse(sessionStorage.getItem("user"));

  return (
    <div className="main-content">
      <Container fixed className="about-container">
        <div className="breadcrumb-back">
          <CButton
            sx={{ border: " 0 !important" }}
            onClick={() => navigate("/module")}
          >
            <RightArrowIcon />
          </CButton>
          <CustomBreadcrumbs
            links={[
              {
                name: "Dashboard",
                href: "/module",
              },
              {
                name: "About Employer",
                href: "",
              },
            ]}
          />
        </div>
        <div className="card-box about-employer-content">
          <div className="employer-logo">
            {userDetails && (
              <img
                src={
                  getFormattedUrl(userDetails?.employer?.logo) || employerLogo
                }
                alt="employer logo"
              />
            )}
          </div>
          {userDetails && (
            <div className="employer-details">
              <h3>Employer Details</h3>
              <div className="details-list">
                <span>Employer Name:</span>
                <p>{userDetails?.employer?.title}</p>
              </div>
              <div className="details-list">
                <span>Description:</span>
                <p>{userDetails?.employer?.description}</p>
              </div>
              {userDetails?.employer?.employerIntroductionImage && (
                <div className="details-list employer-img-list">
                  <span>Employer Introduction:</span>
                  <img
                    src={userDetails?.employer?.employerIntroductionImage}
                    alt="Employer Introduction"
                  />
                </div>
              )}
              {userDetails?.employer?.jobDescriptionImage && (
                <div className="details-list employer-img-list">
                  <span>Job description:</span>
                  <img
                    src={userDetails?.employer?.jobDescriptionImage}
                    alt="Job description"
                  />
                </div>
              )}
              {userDetails?.employer?.employerInformationImage && (
                <div className="details-list employer-img-list">
                  <span>Employer Information:</span>
                  <img
                    src={userDetails?.employer?.employerInformationImage}
                    alt="Employer Information"
                  />
                </div>
              )}
              {userDetails?.employer?.yourEarningsImage && (
                <div className="details-list employer-img-list">
                  <span>Your earnings:</span>
                  <img
                    src={userDetails?.employer?.yourEarningsImage}
                    alt="Your earnings"
                  />
                </div>
              )}
              {/* <div className="details-list">
              <span>Employer ID:</span>
              <p>1234567890</p>
            </div> */}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default AboutEmployer;
