import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";

const CDropdown = ({
  buttonText,
  dropdownItems,
  menuWidth,
  className,
  buttonIcon,
  endIcon,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (itemOnClick) => {
    return (event) => {
      if (itemOnClick) {
        itemOnClick(event);
      }
      handleClose();
    };
  };

  return (
    <div className={className}>
      <Button
        disableRipple
        disableFocusRipple
        id="customized-menu-button"
        aria-controls={open ? "customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={endIcon}
        className="dropdown-btn"
      >
        {buttonText}
        <span>{buttonIcon}</span>
        {children}
      </Button>
      <Menu
        id="customized-menu"
        MenuListProps={{
          "aria-labelledby": "customized-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="dropdown-items"
        PaperProps={{
          sx: {
            width: menuWidth || 160, // Set the width of the menu
          },
        }}
      >
        {dropdownItems.map((item, index) => (
          <MenuItem
            key={index}
            // onClick={handleClose}
            disableRipple
            onClick={handleMenuItemClick(item.onClick)}
          >
            {item.icon}
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CDropdown;
