import React from "react";
import DetailsImg from "../../../assets/images/task-details-img.png";

const TaskViewImage = ({ task }) => {
  const { name } = task;

  return (
    <>
      <div className="task-details-heading assessment-task-heading">
        <p>{name}</p>
      </div>
      <div className="details-img">
        <img src={DetailsImg}  alt=""/>
      </div>
    </>
  );
};

export default TaskViewImage;
