import React from "react";
import CVideoPlayer from "../../../components/CVideoPlayer";
const TaskViewVideo = ({ task }) => {
  const { title, details } = task;

  return (
    <>
      <div className="task-details-heading assessment-task-heading">
        <p>{title}</p>
      </div>
      <div className="video-details-main">
        {details && <CVideoPlayer url={details} />}
      </div>
    </>
  );
};

export default TaskViewVideo;
