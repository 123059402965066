import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../slices/authSlice";
import { useIdleTimer } from "react-idle-timer";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { INACTIVITY_TIMER, INACTIVITY_TIMER_POPUP } from "../common/constants";
import CButton from "./CButton";

const UserActivityMonitor = () => {
  const dispatch = useDispatch();

  const timeout = INACTIVITY_TIMER;
  const promptBeforeIdle = INACTIVITY_TIMER_POPUP;

  const [state, setState] = useState("Active");
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    dispatch(logoutUser()).then((res) => {
      if (!res.error) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
      }
    });
  };

  const onIdle = () => {
    handleLogout();
    setState("Idle");
    setOpen(false);
  };

  const onActive = () => {
    setState("Active");
    setOpen(false);
  };

  const onPrompt = () => {
    setState("Prompted");
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? "seconds" : "second";

  return (
    <Dialog open={open}>
      <DialogTitle>Session Expiring</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your session is about to expire in {remaining} seconds. Do you want to
          continue?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <CButton onClick={handleStillHere} className="btn-primary">
          Continue
        </CButton>
      </DialogActions>
    </Dialog>
  );
};

export default UserActivityMonitor;
