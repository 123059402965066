import { Container } from "@mui/material";
import React from "react";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import CButton from "../../../components/CButton";
import CustomBreadcrumbs from "../../../components/breadcrumbs/CustomBreadcrumbs";

const TaskView = () => {
  return (
    <>
      <div className="main-content">
        <Container fixed className="task-view">
          <div className="breadcrumb-back">
            <CButton sx={{ border: " 0 !important" }}>
              <KeyboardBackspaceOutlinedIcon />
            </CButton>
            <CustomBreadcrumbs
              links={[
                {
                  name: "Home",
                  href: "",
                },
                {
                  name: "Task View",
                  href: "",
                },
              ]}
            />
          </div>
          <div className="card-box">
            <h3>Module Name</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
            <div className="task-btn">
              <CButton className="btn-primary">Start</CButton>
            </div>
            <ul className="task-view-list">
              <li className="list-item">Introduction</li>
              <li className="list-item">User and important of marketing</li>
              <li className="list-item">Understanding user flow</li>
            </ul>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TaskView;
