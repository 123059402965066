import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import RightArrowIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import {
  Box,
  Container,
  IconButton,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FILE_SIZE } from "../../common/constants";
import CButton from "../../components/CButton";
import CFormProvider from "../../components/CFormProvider";
import CFormTextField from "../../components/CFormTextField";
import CustomBreadcrumbs from "../../components/breadcrumbs/CustomBreadcrumbs";
import {
  fetchProfileDetails
} from "../../slices/profileSlice";
import { postSupport } from "../../slices/settingSlice";

const Support = ({ profileImgUrl, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profileDetails } = useSelector((state) => state.profile);
  const ProfileSchema = Yup.object().shape({
    firstName: Yup.string().required("Name is required"),
    lastName: Yup.string().required("Surname is required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    description: Yup.string().required("Description is required"),
    lmaContactNo: Yup.string().required("LMA Contact is required"),
    employerName: Yup.string().required("Employer Name is required"),
    employeeNumber: Yup.string().required("Employee Number is required"),
  });

  const [defaultValues, setDefaultValues] = useState({
    firstName: "",
    lastName: "",
    lmaContactNo: "",
    employerName: "",
    employeeNumber: "",
    email: "",
    description: "",
  });

  const methods = useForm({
    resolver: yupResolver(ProfileSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    // formState: { isSubmitting },
  } = methods;

  const docFile = watch("file");

  useEffect(() => {
    profileDetails !== null && setDefaultValues(profileDetails);
  }, [profileDetails]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  const onDropDoc = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileType = file.type;
    //fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    if (
      file &&
      (fileType.startsWith("image/") ||
        fileType === "application/pdf" ||
        fileType.startsWith("video/")) &&
      file.size <= FILE_SIZE
    ) {
      setValue("file", file);
    } else {
      toast.error(
        "The uploaded file must be Image, PDF or Video and less than 10 MB"
      );
    }
  };

  const { getRootProps: getDocRootProps, getInputProps: getDocInputProps } =
    useDropzone({
      onDrop: onDropDoc,
      accept: ".pdf",
      multiple: false,
    });

  useEffect(() => {
    dispatch(fetchProfileDetails());
  }, [dispatch]);

  const onSubmit = (values) => {
    const formDataPayload = new FormData();
    formDataPayload.append("firstName", values.firstName);
    formDataPayload.append("lastName", values.lastName);
    formDataPayload.append("employeeNumber", values.employeeNumber);
    formDataPayload.append("lmaContactNo", values.lmaContactNo);
    formDataPayload.append("email", values.email);
    formDataPayload.append("description", values.description);
    if (values.file) formDataPayload.append("file", values.file);

    dispatch(postSupport(formDataPayload)).then((res) => {
      if (!res.error) {
        toast.success(res.payload.message);
        setTimeout(() => {
          navigate("/module");
        }, 2000);
      }
    });
  };

  return (
    <>
      <div className="main-content">
        <Container fixed className="setting-container">
          <div className="breadcrumb-back">
            <CButton
              sx={{ border: " 0 !important" }}
              onClick={() => navigate("/module")}
            >
              <RightArrowIcon />
            </CButton>
            <CustomBreadcrumbs
              links={[
                {
                  name: "Dashboard",
                  href: "/module",
                },
                {
                  name: "Support",
                  href: "",
                },
              ]}
            />
          </div>
          <div className="setting-content support-content">
            <div className="card-box col-right">
              <h3>Support</h3>
              <div className="edit-profile-form">
                <CFormProvider methods={methods} onSubmit={handleSubmit()}>
                  <CFormTextField
                    label="First Name"
                    name="firstName"
                    // placeholder="John"
                    // value="John"
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <CFormTextField
                    label="Last Name"
                    name="lastName"
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <CFormTextField
                    label="Employee Number"
                    name="employeeNumber"
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <CFormTextField
                    label="LMA Contact Number"
                    name="lmaContactNo"
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <CFormTextField
                    label="Email Address"
                    name="email"
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: "100% !important" }}
                  />
                  <CFormTextField
                    label="Description"
                    name="description"
                    multiline
                    rows={4}
                    maxRows={4}
                    sx={{
                      mb: 1,
                      width: "100% !important",
                      marginBottom: "0 !important",
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <div className="CTextField support-file">
                    <label>Upload</label>
                    <Box {...getDocRootProps()} className="upload-input">
                      <input {...getDocInputProps()} />
                      <Typography>
                        {docFile
                          ? docFile.name
                          : "Drag and drop a file here, or click to select one"}
                      </Typography>
                      {docFile && (
                        <IconButton
                          onClick={(e) => {
                            setValue("file", null);
                            e.stopPropagation();
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </Box>
                  </div>
                  <div className="submit-btn">
                    <CButton
                      onClick={handleSubmit(onSubmit)}
                      className="btn-primary"
                    >
                      Submit
                    </CButton>
                  </div>
                </CFormProvider>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Support;
