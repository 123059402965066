// TextArea.js
import React from "react";
import { TextField, TextareaAutosize, InputLabel } from "@mui/material";

const CTextArea = ({
  label,
  value,
  onChange,
  labelText,
  additionalText,
  ...props
}) => {
  return (
    <div className="CTextarea">
      <InputLabel shrink>
        <span className="additional-label">{additionalText}</span> {labelText}
      </InputLabel>
      <TextField
        value={value}
        onChange={onChange}
        variant="outlined"
        fullWidth
        multiline
        autoFocus
        InputProps={{
          inputComponent: TextareaAutosize,
          inputProps: {
            style: { minHeight: "auto" },
            ...props,
          },
        }}
      />
    </div>
  );
};

export default CTextArea;
