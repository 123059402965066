import ArrowBackIosNewOutlined from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ASSIGNMENT_QUESTION_LIMIT } from "../../../common/constants";
import CButton from "../../../components/CButton";
import CTextArea from "../../../components/CTextarea";
import {
  fetchAssignmentList,
  resetAssignment,
  submitAssignment,
} from "../../../slices/assignmentSlice";
import { useNavigate } from "react-router-dom";

const AssessmentView = ({ task }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [answer, setAnswer] = useState("");
  const [preSaved, setPreSaved] = useState(false);
  const { assignmentList, hasNextPage, hasPrevPage, totalDocs } = useSelector(
    (state) => state.assignment
  );

  useEffect(() => {
    dispatch(resetAssignment());
  }, []);

  useEffect(() => {
    if (assignmentList?.length > 0) {
      setAnswer(assignmentList[0]?.answer || "");
      if(assignmentList[0].answer?.length > 0) {
        setPreSaved(true)
      }
    }
  }, [assignmentList]);
  useEffect(() => {
    const fetchAllAssignmentsAPI = (moduleId, taskId) => {
      let payload = {
        page: page,
        moduleId: moduleId,
        taskId: taskId,
        limit: ASSIGNMENT_QUESTION_LIMIT,
      };
      dispatch(fetchAssignmentList(payload));
    };
    task && fetchAllAssignmentsAPI(task?.module?._id, task?._id);
    setAnswer("");
  }, [task, page, dispatch]);

  const onAnswerSubmit = (data) => {
    dispatch(submitAssignment(data)).then((res) => {
      if (!res.error) {
        hasNextPage && setPage(page + 1);
        if (!hasNextPage && page === totalDocs) {
          navigate(`/module/${task?.module?._id}`);
        }
      }
    });
  };

  return (
    <>
      <div className="task-details-heading assessment-task-heading">
        <p>
          Question {page} of {totalDocs}
        </p>
        <div>
          <CButton
            className="btn-text btn-text-prev"
            disabled={!hasPrevPage}
            onClick={() => setPage(page - 1)}
          >
            <ArrowBackIosNewOutlined />
            Prev
          </CButton>
          <CButton
            className="btn-text"
            disabled={!hasNextPage || !preSaved}
            onClick={() => setPage(page + 1)}
          >
            Next
            <ArrowForwardIosOutlinedIcon />
          </CButton>
        </div>
      </div>
      {/* <div className="task-details-content"> */}
      {/* <h3>Test Your knowledge</h3>
              <p>Answer the following questions to see what you've learned</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p> */}
      <div className="assessment-qa">
        {assignmentList?.map((assignment) => (
          <div key={assignment._id}>
            <CTextArea
              value={answer}
              additionalText={assignment?.title}
              labelText=""
              onChange={(e) => {
                setAnswer(e.target.value);
                if(e.target.value.trim()?.length === 0) {
                  setPreSaved(false);
                }
              }}
            />
            <div className="task-details-continue">
              <CButton
                className="btn-primary"
                onClick={() => {
                  // console.log("assignment", assignment);
                  onAnswerSubmit({
                    moduleId: assignment?.module?._id,
                    taskId: assignment?.task?._id,
                    assignmentId: assignment?._id,
                    assignmentType: assignment?.assignmentType,
                    answer: answer.trim(),
                  });
                }}
                disabled={!answer}
              >
                Submit
              </CButton>
            </div>
          </div>
        ))}
        {/* <CTextArea
          additionalText="Que 2"
          labelText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in"
        />
        <CTextArea
          additionalText="Que 3"
          labelText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in"
        /> */}
        {/* <div className="task-details-continue">
          <CButton
            className="btn-primary"
            onClick={() => onAnswerSubmit()}
            disabled={!answer}
          >
            Submit
          </CButton>
        </div> */}
        {/* <hr />
          <div className="task-details-continue">
            <h4>Next Taks : Basic of sales</h4>
            <CButton className="btn-primary">
              Continue
              <ArrowForwardIosOutlinedIcon className="btn-icon-right" />
            </CButton>
          </div> */}
      </div>
      {/* </div> */}
    </>
  );
};

export default AssessmentView;
