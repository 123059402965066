import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commonService from "../common/commonService";
import { API_URLS, STATUS_CODES } from "../common/constants";
import { toast } from "react-toastify";
import { startLoading, stopLoading } from "./commonSlice";

const initialState = {
  errorMessage: "",
  error: null,
  companyList: [],
  isCompanyAdded: false,
  companyDetails: null,
  isFileDeleted: false,
};

export const postSupport = createAsyncThunk(
  "support/add",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.postRequestForFormData(
      API_URLS.SUPPORT,
      data
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

const settingSlice = createSlice({
  name: "data",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(postSupport.pending, (state) => {
        return {
          ...state,
          error: true,
          isSupportPosted: false,
        };
      })
      .addCase(postSupport.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isSupportPosted: true,
        };
      })
      .addCase(postSupport.rejected, (state, action) => {
        return {
          ...state,
          isSupportPosted: false,
          error: action.payload,
        };
      });
  },
});

const { reducer } = settingSlice;
export default reducer;
