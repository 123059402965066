import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commonService from "../common/commonService";
import { API_URLS, STATUS_CODES } from "../common/constants";
import { toast } from "react-toastify";
import { startLoading, stopLoading } from "./commonSlice";

const initialState = {
  errorMessage: "",
  error: null,
  isContractSigned: false,
};

export const signContract = createAsyncThunk(
  "sign/contract",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.putDataRequest(
      API_URLS.SIGN_CONTRACT,
      data
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    toast.success(res.message);
    return res.data;
  }
);

const contractSlice = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signContract.pending, (state) => {
        return {
          ...state,
          error: null,
        };
      })
      .addCase(signContract.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isContractSigned: true,
        };
      })
      .addCase(signContract.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isContractSigned: false,
        };
      });
  },
});

const { reducer } = contractSlice;
export default reducer;
