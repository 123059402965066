export const API_URLS = {
  LOGIN: "/v1/auth/login",
  LOGOUT: "/v1/auth/logout",
  SIGN_CONTRACT: "/v1/employee/agreeTnc",
  MODULE_LIST: "/v1/module/list",
  TASK: "/v1/task/",
  TASK_LIST: "/v1/task/list",
  ASSIGNMENT_LIST: "/v1/assignments/list",
  SUBMIT_ASSIGNMENT: "/v1/employee/assignment",
  EMPLOYEE: "/v1/employee",
  SEARCH: "/v1/dashboard/search",
  PROFILE_IMAGE: "/v1/employee/profile/image",
  REMOVE_PROFILE_IMAGE: "/v1/employee/removeProfileImage",
  SUPPORT: "/v1/employee/supportLink",
};

export const STATUS_CODES = {
  SUCCESS: 200,
};

export const QUESTION_TASK = "Question";

export const TEXT_TASK = "Text";

export const VIDEO_TASK = "Video";

export const IMAGE_TASK = "Image";

export const ASSIGNMENT_QUESTION_LIMIT = 1;

export const TIMEZONE_OFFSET = 2;

export const INACTIVITY_TIMER = 300_000; //seconds //5 min = 300 second 

export const INACTIVITY_TIMER_POPUP = 20_000; // 20 seconds before show warning popup

export const FILE_SIZE = 10 * 1024 * 1024; // 10 MB
