import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { QUESTION_TASK, TEXT_TASK, VIDEO_TASK } from "../common/constants";
import {
  fetchAssignmentList,
  resetAssignment,
} from "../slices/assignmentSlice";
import CButton from "./CButton";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";

const CAccordion = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mlId } = useParams();
  let {
    showButton,
    //handleClick,
    title,
    description,
    moduleId,
    taskId,
    // showIcon,
    taskType,
    //children,
    expanded,
    onAccordionChange,
  } = props;

  const { assignmentList } = useSelector((state) => state.assignment);

  const fetchAllAssignmentsAPI = (moduleId, taskId) => {
    let payload = {
      page: 1,
      moduleId: moduleId,
      taskId: taskId,
      limit: -1,
    };
    dispatch(fetchAssignmentList(payload));
  };

  const handleAccordionChange = (event, isExpanded) => {
    dispatch(resetAssignment());
    if (taskType === QUESTION_TASK) {
      onAccordionChange(taskId);
      if (isExpanded) {
        fetchAllAssignmentsAPI(moduleId, taskId);
      }
    }
  };

  const navigateToTask = (e) => {
    e.stopPropagation();
    navigate(`/module/${mlId}/task/${taskId}`);
  };

  return (
    <Accordion
      onClick={(e) => {}}
      expanded={expanded}
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        style={{ cursor: taskType === "Question" ? "pointer" : "default" }}
        expandIcon={
          expanded ? (
            <IndeterminateCheckBoxOutlinedIcon />
          ) : taskType === QUESTION_TASK ? (
            <AddBoxOutlinedIcon />
          ) : taskType === TEXT_TASK ? (
            <TextSnippetOutlinedIcon />
          ) : taskType === VIDEO_TASK ? (
            <YouTubeIcon />
          ) : (
            ""
          )
        }
      >
        <div className="acc-title">
          <h5>{title}</h5>
          {/* {showIcon && <VerifiedOutlinedIcon />} */}
        </div>

        {showButton && (
          <CButton
            className="btn-primary accordion-start-btn"
            onClick={(e) => {
              navigateToTask(e);
            }}
          >
            {/* <LockOutlinedIcon /> */}
            Start
          </CButton>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {assignmentList.length > 0 ? (
          <>
            {description && (
              <h5 style={{ paddingTop: "10px", paddingBottom: "5px" }}>
                {description}
              </h5>
            )}
            <ul className="module-task">
              {assignmentList.map((assignment, index) => (
                <li key={index}>{assignment.title}</li>
              ))}
            </ul>
          </>
        ) : (
          <p>No assignments available.</p>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CAccordion;
