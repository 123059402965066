import React, { useState, useEffect } from 'react';
import he from 'he';
import ArrowUp from '@mui/icons-material/KeyboardArrowUp';

const TaskViewDetails = ({ task }) => {
  const { title, details } = task;
  const decodedHtml = he.decode(details);

  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 300) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 300) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className="task-details-heading assessment-task-heading">
        <p>{title}</p>
      </div>
      <div
        className="task-details-main"
        dangerouslySetInnerHTML={{ __html: decodedHtml }}
      />
      {showScroll && (
        <button onClick={scrollToTop} className="scroll-top-btn">
          <ArrowUp />
        </button>
      )}
    </>
  );
};

export default TaskViewDetails;
