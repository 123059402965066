import { configureStore } from "@reduxjs/toolkit";
import {
  authSlice,
  commonSlice,
  contractSlice,
  moduleSlice,
  taskSlice,
  assignmentSlice,
  profileSlice,
  searchSlice,
  settingSlice,
} from "./slices";

const reducer = {
  auth: authSlice,
  contract: contractSlice,
  common: commonSlice,
  module: moduleSlice,
  task: taskSlice,
  assignment: assignmentSlice,
  profile: profileSlice,
  search: searchSlice,
  setting: settingSlice,
};

export default configureStore({
  reducer: reducer,
  devTools: true,
});
