import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { List, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ProfileIcon } from "../../assets/images/profile.svg";
import { ReactComponent as WalletIcon } from "../../assets/images/wallet.svg";
import { NavLink } from "react-router-dom";
const SettingSidebar = ({ profileImgUrl, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profileDetails } = useSelector((state) => state.profile);

  const handleContractDownload = (event) => {
    event.stopPropagation();
    if (profileDetails?.contractDocument) {
      const url =
        profileDetails.contractDocument.startsWith("http") ||
        profileDetails.contractDocument.startsWith("https")
          ? profileDetails.contractDocument
          : `http://${profileDetails.contractDocument}`;

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "contract.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading contract:", error);
        });
    }
  };

  return (
    <>
      <h3>Settings</h3>
      <List className="profile-menu">
        <ListItem>
          <NavLink
            to={"/profile"}
            className={({ isActive }) =>
              isActive ? "active menu_item1" : "menu_item1"
            }
          >
            <ProfileIcon />
            Profile
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            to={"/wallet"}
            className={({ isActive }) =>
              isActive ? "active menu_item2" : "menu_item2"
            }
          >
            <WalletIcon />
            Wallet
          </NavLink>
        </ListItem>
        {window.location.pathname.includes("profile") && (
          <ListItem className="profile-logout-btn">
            <NavLink onClick={(e) => handleContractDownload(e)}>
              <DownloadRoundedIcon />
              Download Contract
            </NavLink>
            {/* <Link onClick={() => handleLogout()}>
                    <LogoutRoundedIcon />
                    Log Out
                  </Link>
                    Sign Out
                  </Link> */}
          </ListItem>
        )}
      </List>
    </>
  );
};

export default SettingSidebar;
