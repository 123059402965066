import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import commonService from '../common/commonService';
import { API_URLS, STATUS_CODES } from '../common/constants';
import { toast } from 'react-toastify';
import { startLoading, stopLoading } from './commonSlice';

const initialState = {
  errorMessage: '',
  error: null,
  moduleList: [],
  totalPages: null,
  hoursPerMonth: null,
  hasNextPage: true,
};

export const fetchModuleList = createAsyncThunk(
  'module/contract',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getRequest(
      API_URLS.MODULE_LIST + `?${commonService.getQueryFromObject(data)}`
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

const moduleSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchModuleList.pending, (state) => {
        return {
          ...state,
          error: null,
          moduleList: [],
          hasNextPage: true,
        };
      })
      .addCase(fetchModuleList.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          moduleList: action.payload.data,
          totalPages: action.payload.totalPages,
          hoursPerMonth: action.payload.hoursPerMonth,
          hasNextPage: action.payload.hasNextPage,
        };
      })
      .addCase(fetchModuleList.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          hasNextPage: true,
        };
      });
  },
});

const { reducer } = moduleSlice;
export default reducer;
