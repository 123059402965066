import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import UserActivityMonitor from "./UserActivityMonitor";

const ProtectedRoute = ({ roles, children }) => {
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const authStatus = token ? true : false;
  const currentRole =
    localStorage.getItem("role") || sessionStorage.getItem("role");
  const navigate = useNavigate();

  useEffect(() => {
    if (!authStatus) {
      navigate("/");
    }
  }, [authStatus, navigate]);

  if (typeof roles !== "undefined" && !roles.includes(currentRole)) {
    return <p>You do not have permission to access this page</p>;
  }

  return authStatus ? (
    <>
      <UserActivityMonitor />
      <Header />
      {children}
    </>
  ) : null;
};

export default ProtectedRoute;
