import moment from 'moment';
import { TIMEZONE_OFFSET } from './constants';

export const convertToSouthAfricaTime = (
  utcDateString,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  const utcDate = moment.utc(utcDateString);
  const saDate = utcDate.utcOffset(TIMEZONE_OFFSET);
  return saDate.format(format);
};

export const getFormattedUrl = (url) => {
  if (!url) return '';

  // Check if the URL starts with 'http' or 'https'
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  } else {
    return `http://${url}`;
  }
};

export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};
