import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CAccordion from "../../components/CAccordion";
import CButton from "../../components/CButton";
import CustomBreadcrumbs from "../../components/breadcrumbs/CustomBreadcrumbs";
import { fetchTaskList } from "../../slices/taskSlice";
import { resetSearchResult } from "../../slices/searchSlice";
const ModuleView = () => {
  const { mlId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { taskList } = useSelector((state) => state.task);
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  useEffect(() => {
    dispatch(resetSearchResult());
  }, []);

  const handleAccordionChange = (taskId) => {
    setExpandedAccordion((prev) => (prev === taskId ? null : taskId));
  };

  useEffect(() => {
    const fetchAllTasksAPI = (moduleId) => {
      let payload = {
        page: 1,
        moduleId: moduleId,
        limit: -1,
      };
      dispatch(fetchTaskList(payload));
    };
    fetchAllTasksAPI(mlId);
  }, [mlId, dispatch]);

  return (
    <>
      {taskList && (
        <div className="main-content">
          <Container fixed className="module-view">
            {!!taskList.length && (
              <div className="breadcrumb-back">
                <CButton
                  sx={{ border: " 0 !important" }}
                  onClick={() => navigate("/module")}
                >
                  <KeyboardBackspaceOutlinedIcon />
                </CButton>

                <CustomBreadcrumbs
                  links={[
                    {
                      name: "Dashboard",
                      href: "/module",
                    },
                    {
                      name: `${taskList[0]?.module?.title}`,
                      href: "",
                    },
                  ]}
                />
              </div>
            )}
            <div className="card-box">
              <h3>{taskList[0]?.module?.title}</h3>
              <p>{taskList[0]?.module?.description} </p>
              {/* <div className="module-btn">
              <CButton className="btn-primary">
                <LockOutlinedIcon className="btn-icon-left" />
                Go to next Module
              </CButton>
            </div> */}
            </div>
            <div className="card-box">
              <div className="module-accordion">
                <h5 className="module-title">Work</h5>
                {taskList.map((task) => (
                  <CAccordion
                    key={task._id}
                    title={task.title}
                    moduleId={task.module._id}
                    taskId={task._id}
                    description={task.description}
                    taskType={task.taskType}
                    expanded={expandedAccordion === task._id}
                    onAccordionChange={handleAccordionChange}
                    showButton
                    showIcon
                  />
                ))}
                {/* <CAccordion title="Sales Personality" showButton showIcon>
                <ul className="module-task">
                  <li>Task 1</li>
                  <li>Assignment</li>
                </ul>
              </CAccordion>
              <CAccordion title=" Basic of Marketing" showButton showIcon>
                <ul className="module-task">
                  <li>Task 1</li>
                  <li>Assignment</li>
                </ul>
              </CAccordion> */}
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default ModuleView;
