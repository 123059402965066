import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import commonService from '../common/commonService';
import { API_URLS, STATUS_CODES } from '../common/constants';
import { toast } from 'react-toastify';
import { startLoading, stopLoading } from './commonSlice';

const initialState = {
  errorMessage: '',
  error: null,
  taskList: [],
  taskDetails: null,
  totalPages: null,
};

export const fetchTaskList = createAsyncThunk(
  'module/task',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getRequest(
      API_URLS.TASK_LIST + `?${commonService.getQueryFromObject(data)}`
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const fetchTaskDetails = createAsyncThunk(
  'module/taskDetails',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getByIdRequest(API_URLS.TASK, data);
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

const taskSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskList.pending, (state) => {
        return {
          ...state,
          error: null,
          taskList: [],
        };
      })
      .addCase(fetchTaskList.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          taskList: action.payload.data,
          totalPages: action.payload.totalPages,
        };
      })
      .addCase(fetchTaskList.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
        };
      })
      .addCase(fetchTaskDetails.pending, (state) => {
        return {
          ...state,
          error: null,
          taskDetails: null,
        };
      })
      .addCase(fetchTaskDetails.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          taskDetails: action.payload.data,
        };
      })
      .addCase(fetchTaskDetails.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
        };
      });
  },
});

const { reducer } = taskSlice;
export default reducer;
