import React from "react";
import ReactPlayer from "react-player";

const CVideoPlayer = ({
  url,
  width = "100%",
  height = "100%",
  controls = true,
}) => {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        width={width}
        height={height}
        controls={controls}
      />
    </div>
  );
};

export default CVideoPlayer;
