import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commonService from "../common/commonService";
import { API_URLS, STATUS_CODES } from "../common/constants";
import { toast } from "react-toastify";
import { startLoading, stopLoading } from "./commonSlice";

const initialState = {
  errorMessage: "",
  error: null,
  isAuthenticated: false,
  userDetails: null,
  passwordChanged: false,
};

export const loginUser = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.noTokenFormDataPostRequest(
      API_URLS.LOGIN,
      data
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    toast.success(res.message);
    return res.data;
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getRequest(API_URLS.LOGOUT);
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    toast.success(res.message);
    return res.data;
  }
);

const authSlice = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        return {
          ...state,
          error: null,
        };
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isAuthenticated: true,
          userDetails: action.payload,
        };
      })
      .addCase(loginUser.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isAuthenticated: false,
        };
      })
      .addCase(logoutUser.pending, (state) => {
        return {
          ...state,
          error: null,
        };
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isAuthenticated: false,
          userDetails: action.payload,
        };
      })
      .addCase(logoutUser.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isAuthenticated: true,
        };
      });
  },
});

const { reducer } = authSlice;
export default reducer;
