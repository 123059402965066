import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import ProfileIcon from "@mui/icons-material/PermIdentityOutlined";
import SupportIcon from "@mui/icons-material/SupportAgentOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/lma-logo.png";
import { logoutUser } from "../slices/authSlice";
import { fetchSearchDetails, resetSearchResult } from "../slices/searchSlice";
import CDropdown from "./CDropdown";
import { debounce, getFormattedUrl } from "../common/utils";

const Header = ({ profileImgUrl, ...props }) => {
  const userDetails =
    JSON.parse(localStorage.getItem("user")) ||
    JSON.parse(sessionStorage.getItem("user"));

  const profileImg =
    localStorage.getItem("profileImg") || sessionStorage.getItem("profileImg");

  const { profileDetails } = useSelector((state) => state.profile);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchResult } = useSelector((state) => state.search);

  const handleLogout = () => {
    dispatch(logoutUser()).then((res) => {
      if (!res.error) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
      }
    });
  };

  const handleRedirect = () => {
    navigate("/profile");
  };

  const handleSupportRedirect = () => {
    navigate("/support");
  };

  const handleRedirectToEmployer = () => {
    navigate("/about-employer");
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((searchValue) => {
        if (searchValue?.length > 3) {
          dispatch(fetchSearchDetails(searchValue));
        } else {
          setOptions([]);
        }
      }, 300),
    [dispatch]
  );

  const handleInputChange = (event, newInputValue, reason) => {
    if (event === null) return;
    let searchValue = newInputValue || "";
    setInputValue(searchValue);

    if (event?.type === "blur" || event?.type === undefined) {
      setInputValue("");
    }

    debouncedSearch(searchValue);
  };

  const handleOpenDocument = () => {
    if (userDetails?.employer?.doc !== null) {
      let downloadLink = getFormattedUrl(userDetails?.employer?.doc);
      const link = document.createElement("a");
      link.href = downloadLink;
      link.target = "_blank";
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    setOptions(searchResult);
  }, [searchResult]);

  const EmployerItems = [
    {
      text: "About Employer",
      onClick: () => {
        handleRedirectToEmployer();
      },
    },
    // {
    //   text: 'About Training',
    //   onClick: () => {
    //     handleOpenDocument();
    //   },
    // },
  ];
  const handleOpen = () => {
    setIsPopperOpen(true);
  };

  const handleClose = () => {
    setIsPopperOpen(false);
  };
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  useEffect(() => {
    if (isPopperOpen) {
      document.body.classList.add("popper-open");
    } else {
      document.body.classList.remove("popper-open");
    }
  }, [isPopperOpen]);

  const profileItems = [
    {
      icon: <ProfileIcon />,
      text: "Profile",
      onClick: () => {
        handleRedirect();
      },
    },
    {
      icon: <SupportIcon />,
      text: "Support",
      onClick: () => {
        handleSupportRedirect();
      },
    },
    {
      icon: <LogoutIcon />,
      text: "Logout",
      onClick: () => {
        handleLogout();
      },
    },
  ];

  const handleChange = (e, value) => {
    if (value !== null && value?.type) {
      value?.type === "task"
        ? navigate(`/module/${value?.moduleId}/task/${value?.id}`)
        : navigate(`/module/${value?.id}`);
      setInputValue(""); // Reset the input value
      // setOptions([]); // Clear the options
      // dispatch(resetSearchResult());
    }
  };

  return (
    <>
      <div
        className={`header-content ${options?.length ? "options-open" : ""}`}
      >
        <div className="header-logo" onClick={() => navigate("/module")}>
          <img
            src={getFormattedUrl(userDetails?.employer?.logo) || Logo}
            alt="logo"
          />
        </div>

        <div className="header-middle">
          <div className="header-search-field desktop-search-field">
            <div className="CAutoComplete">
              <Autocomplete
                clearOnBlur={true}
                blurOnSelect={true}
                disableClearable={inputValue === ""}
                freeSolo={inputValue === ""}
                fullWidth
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onChange={handleChange}
                options={options || []}
                getOptionLabel={(option) => option.title || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={"Search"}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          <CDropdown
            buttonText="Employer Info"
            endIcon={<KeyboardArrowDownOutlinedIcon />}
            dropdownItems={EmployerItems}
          />
        </div>

        <CDropdown
          dropdownItems={profileItems}
          endIcon={<KeyboardArrowDownOutlinedIcon />}
          className="profile-btn"
        >
          {profileImg && (
            <div className="header-profile">
              {profileImg !== "null" ? (
                <span>
                  <img src={profileImg} alt="profile" />
                </span>
              ) : (
                <span className="profile-name-first-letter">
                  {userDetails?.firstName[0]}
                </span>
              )}
            </div>
          )}
          <span className="user-name">
            {userDetails?.firstName + " " + userDetails?.lastName}
          </span>
        </CDropdown>
      </div>
      <div
        className={`search-field-mobile ${
          options?.length ? "options-open" : ""
        }`}
      >
        <div className="header-search-field">
          <div className="CAutoComplete">
            <Autocomplete
              clearOnBlur={true}
              blurOnSelect={true}
              disableClearable={inputValue === ""}
              fullWidth
              freeSolo={inputValue === ""}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              onChange={handleChange}
              options={options || []}
              getOptionLabel={(option) => option.title || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={"Search"}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
