import React, { Suspense, lazy, useEffect } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import ModuleView from "../pages/module/ModuleView";
import PageNotFound from "../pages/page-not-found/PageNotFound.js";
import Profile from "../pages/setting/Profile.js";
import TaskView from "../pages/task/TaskView.js";
import AssessmentView from "../pages/task/components/AssessmentView";
import TaskViewDetails from "../pages/task/components/TaskViewDetails";
import TaskViewList from "../pages/task/components/TaskViewList.js";
import TaskViewVideo from "../pages/task/components/TaskViewVideo";
import AboutEmployer from "../pages/about-employer/AboutEmployer.js";
import Wallet from "../pages/setting/Wallet.js";
import Support from "../pages/setting/Support.js";

// ----------------------------------------------------------------------
const Loadable = (Component) => (props) =>
  (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
// ----------------------------------------------------------------------

const LoginPage = Loadable(lazy(() => import("../pages/auth/Login")));
const HomePage = Loadable(lazy(() => import("../pages/home/Home")));
const ContractPage = Loadable(lazy(() => import("../pages/contract/Contract")));

const Router = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/module");
    }
  }, [navigate]);

  return useRoutes([
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "/module",
      element: (
        <ProtectedRoute>
          <HomePage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/contract",
      element: (
        <ProtectedRoute>
          <ContractPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/module/:mlId",
      element: (
        <ProtectedRoute>
          <ModuleView />
        </ProtectedRoute>
      ),
    },
    {
      path: "/module/:mlId/task/:tsId",
      element: (
        <ProtectedRoute>
          <TaskView />
        </ProtectedRoute>
      ),
    },
    {
      path: "/taskview",
      element: (
        <ProtectedRoute>
          <TaskViewList />
        </ProtectedRoute>
      ),
    },
    {
      path: "/taskviewdetails",
      element: (
        <ProtectedRoute>
          <TaskViewDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: "/taskviewimage",
      element: (
        <ProtectedRoute>
          <TaskView />
        </ProtectedRoute>
      ),
    },
    {
      path: "/taskviewvideo",
      element: (
        <ProtectedRoute>
          <TaskViewVideo />
        </ProtectedRoute>
      ),
    },
    {
      path: "/assessmentview",
      element: (
        <ProtectedRoute>
          <AssessmentView />
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile",
      element: (
        <ProtectedRoute>
          <Profile />
        </ProtectedRoute>
      ),
    },
    {
      path: "/support",
      element: (
        <ProtectedRoute>
          <Support />
        </ProtectedRoute>
      ),
    },
    {
      path: "/wallet",
      element: (
        <ProtectedRoute>
          <Wallet />
        </ProtectedRoute>
      ),
    },
    {
      path: "/about-employer",
      element: (
        <ProtectedRoute>
          <AboutEmployer />
        </ProtectedRoute>
      ),
    },
  ]);
};

export default Router;
