import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import CLoader from './components/CLoader';
import { Toast } from './components/Toast';
import Router from './routes/Router';

function App() {
  return (
    <>
      <Toast />
      <CLoader />
      <Router />
    </>
  );
}

export default App;
