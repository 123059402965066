import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commonService from "../common/commonService";
import { API_URLS, STATUS_CODES } from "../common/constants";
import { toast } from "react-toastify";
import { startLoading, stopLoading } from "./commonSlice";

const initialState = {
  errorMessage: "",
  error: null,
  assignmentList: [],
  submittedDetails: null,
  totalPages: null,
  hasNextPage: false,
  hasPrevPage: false,
};

export const fetchAssignmentList = createAsyncThunk(
  "task/assignment",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getRequest(
      API_URLS.ASSIGNMENT_LIST + `?${commonService.getQueryFromObject(data)}`
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const submitAssignment = createAsyncThunk(
  "submit/assignment",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.postRequest(
      API_URLS.SUBMIT_ASSIGNMENT,
      data
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

const assignmentSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    resetAssignment: (state) => {
      return {
        ...state,
        error: null,
        assignmentList: [],
        totalRows: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignmentList.pending, (state) => {
        return {
          ...state,
          error: null,
          hasNextPage: false,
          hasPrevPage: false,
        };
      })
      .addCase(fetchAssignmentList.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          assignmentList: action.payload.data,
          hasNextPage: action.payload.hasNextPage,
          hasPrevPage: action.payload.hasPrevPage,
          totalPages: action.payload.totalPages,
          totalDocs: action.payload.totalDocs,
        };
      })
      .addCase(fetchAssignmentList.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
        };
      })
      .addCase(submitAssignment.pending, (state) => {
        return {
          ...state,
          error: null,
          isAssignmentSubmitted: false,
        };
      })
      .addCase(submitAssignment.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          submittedDetails: action.payload.data,
          isAssignmentSubmitted: true,
        };
      })
      .addCase(submitAssignment.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isAssignmentSubmitted: false,
        };
      });
  },
});

export const { resetAssignment } = assignmentSlice.actions;
const { reducer } = assignmentSlice;
export default reducer;
