import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import commonService from '../common/commonService';
import { API_URLS, STATUS_CODES } from '../common/constants';
import { toast } from 'react-toastify';

const initialState = {
  errorMessage: '',
  error: null,
  searchResult: null,
};

export const fetchSearchDetails = createAsyncThunk(
  'search/details',
  async (data, { rejectWithValue }) => {
    const res = await commonService.getByIdRequest(API_URLS.SEARCH, data);
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

const searchSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    resetSearchResult: (state) => {
      return {
        ...state,
        error: null,
        searchResult: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchDetails.pending, (state) => {
        return {
          ...state,
          error: null,
          searchResult: [],
        };
      })
      .addCase(fetchSearchDetails.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          searchResult: action.payload.data,
        };
      })
      .addCase(fetchSearchDetails.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          searchResult: [],
        };
      });
  },
});

export const { resetSearchResult } = searchSlice.actions;
const { reducer } = searchSlice;
export default reducer;
