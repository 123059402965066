import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Container } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IMAGE_TASK,
  QUESTION_TASK,
  TEXT_TASK,
  VIDEO_TASK,
} from '../../common/constants';
import CButton from '../../components/CButton';
import CustomBreadcrumbs from '../../components/breadcrumbs/CustomBreadcrumbs';
import { fetchTaskDetails } from '../../slices/taskSlice';
import AssessmentView from './components/AssessmentView';
import TaskViewDetails from './components/TaskViewDetails';
import TaskViewImage from './components/TaskViewImage';
import TaskViewVideo from './components/TaskViewVideo';
import { resetSearchResult } from '../../slices/searchSlice';

const TaskView = () => {
  const { tsId, mlId } = useParams();
  const { taskDetails } = useSelector((state) => state.task);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetSearchResult());
  }, []);
  

  useEffect(() => {
    dispatch(fetchTaskDetails(tsId));
  }, [tsId, mlId, dispatch]);

  return (
    <>
      {taskDetails && (
        <div className="main-content">
          <Container fixed className="task-view">
            <div className="breadcrumb-back">
              <CButton
                sx={{ border: ' 0 !important' }}
                onClick={() => navigate(`/module/${mlId}`)}
              >
                <KeyboardBackspaceOutlinedIcon />
              </CButton>
              <CustomBreadcrumbs
                links={[
                  {
                    name: 'Dashboard',
                    href: '/module',
                  },
                  {
                    name: `${taskDetails?.module?.title}`,
                    href: `/module/${mlId}`,
                  },
                  {
                    name: `${taskDetails?.title}`,
                    href: '',
                  },
                ]}
              />
            </div>
            <div className="card-box details-task">
              {/* {taskDetails?.taskType !== QUESTION_TASK && (
              <div className="task-details-heading">
                <p>{taskDetails?.name}</p>
              </div>
            )} */}
              <div className="task-details-content">
                {/* <h3>Introduction</h3> */}
                {taskDetails?.taskType === TEXT_TASK ? (
                  <TaskViewDetails task={taskDetails} />
                ) : taskDetails?.taskType === IMAGE_TASK ? (
                  <TaskViewImage task={taskDetails} />
                ) : taskDetails?.taskType === VIDEO_TASK ? (
                  <TaskViewVideo task={taskDetails} />
                ) : taskDetails?.taskType === QUESTION_TASK ? (
                  <AssessmentView task={taskDetails} />
                ) : (
                  ''
                )}
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default TaskView;
