import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import commonService from '../common/commonService';
import { API_URLS, STATUS_CODES } from '../common/constants';
import { toast } from 'react-toastify';
import { startLoading, stopLoading } from './commonSlice';

const initialState = {
  errorMessage: '',
  error: null,
  profileDetails: null,
  isProfileUploaded: null,
  isProfileRemoved: null,
};

export const fetchProfileDetails = createAsyncThunk(
  'profile/details',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getRequest(API_URLS.EMPLOYEE);
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const uploadProfileImage = createAsyncThunk(
  'image/upload',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.postRequestForFormData(
      API_URLS.PROFILE_IMAGE,
      data
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const removeProfileImage = createAsyncThunk(
  'image/remove',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getRequest(API_URLS.REMOVE_PROFILE_IMAGE);
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

const profileSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileDetails.pending, (state) => {
        return {
          ...state,
          error: null,
        };
      })
      .addCase(fetchProfileDetails.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          profileDetails: action.payload.data,
        };
      })
      .addCase(fetchProfileDetails.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
        };
      })
      .addCase(uploadProfileImage.pending, (state) => {
        return {
          ...state,
          error: null,
          isProfileUploaded: false,
        };
      })
      .addCase(uploadProfileImage.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isProfileUploaded: true,
        };
      })
      .addCase(uploadProfileImage.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isProfileUploaded: false,
        };
      })
      .addCase(removeProfileImage.pending, (state) => {
        return {
          ...state,
          error: null,
          isProfileRemoved: false,
        };
      })
      .addCase(removeProfileImage.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isProfileRemoved: true,
        };
      })
      .addCase(removeProfileImage.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isProfileRemoved: false,
        };
      });
  },
});

const { reducer } = profileSlice;
export default reducer;
