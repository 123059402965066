import React, { useState, useEffect } from 'react';
import ImageUploading from 'react-images-uploading';
import CButton from './CButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { getFormattedUrl } from '../common/utils';
import { useSelector } from 'react-redux';

const CImageUpload = ({ onChange, value = [], maxNumber, defaultImage }) => {
  const { profileDetails } = useSelector((state) => state.profile);
  const [imageList, setImageList] = useState(value);
  const [isImageNew, setIsImageNew] = useState(false);

  useEffect(() => {
    if (defaultImage && imageList.length === 0) {
      setImageList([{ data_url: getFormattedUrl(defaultImage) }]);
    }
  }, [defaultImage, imageList]);

  const handleOnChange = (newImageList) => {
    setIsImageNew(true);
    onChange(newImageList);
    setImageList(newImageList);
  };

  return (
    <ImageUploading
      value={imageList}
      onChange={handleOnChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({ onImageUpdate, onImageRemove }) => (
        <div className="upload__image-wrapper">
          {profileDetails?.profile === null && (
            <div className="image-item">
              {/* <span className="img-content">
                <img src={defaultImage} alt="profile" />
              </span> */}

              <span className="uploaded-text">
                {profileDetails?.firstName[0]}
              </span>
              <div className="image-item__btn-wrapper">
                <CButton onClick={() => onImageUpdate(0)} className="btn-text">
                  <EditIcon />
                </CButton>
                <span>|</span>
                <CButton
                  onClick={() => onImageRemove(0)}
                  className="btn-text"
                  disabled={profileDetails?.profile === null}
                >
                  <DeleteIcon />
                </CButton>
              </div>
            </div>
          )}
          {profileDetails?.profile !== null &&
            imageList.map((image, index) => (
              <div key={index} className="image-item">
                <span className="img-content">
                  <img
                    src={
                      isImageNew
                        ? image['data_url']
                        : getFormattedUrl(image['data_url'])
                    }
                    alt="profile"
                  />
                </span>
                <div className="image-item__btn-wrapper">
                  <CButton
                    onClick={() => onImageUpdate(index)}
                    className="btn-text"
                  >
                    <EditIcon />
                  </CButton>
                  <span>|</span>
                  <CButton
                    onClick={() => onImageRemove(index)}
                    className="btn-text"
                    disabled={profileDetails?.profile === null}
                  >
                    <DeleteIcon />
                  </CButton>
                </div>
              </div>
            ))}
        </div>
      )}
    </ImageUploading>
  );
};

export default CImageUpload;
